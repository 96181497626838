import * as React from "react";
import { graphql } from "gatsby";
import ComponentTemplatePage from "../../../../components/pages/templates/ComponentTemplatePage";

const ComponentTemplate = ({ data }) => (
  <ComponentTemplatePage
    title="Social Components | UI Components Library | Uizard"
    category="Component Templates"
    url="/templates/component-templates/social/"
    metaDescription="Create a user-friendly social media app or website that looks and feels native to your brand by using Uizard's built-in social UI component templates."
    description="
    h2:Everything you need to design social apps
    <br/>
    Drag-and-drop social component template to create a user-friendly design for your social media.
    <br/>
    Create a user-friendly social media app or website that looks and feels native to your brand by using Uizard's built-in social component template. Available for both web and mobile, this collection of social component template provide you with a variety of designs to fit a variety of use cases.
    <br/>
    h3:Design social apps with Uizard's customizable and engaging social component template
    <br/>
    You can use Uizard to design social apps with social component template, such as private or group chats, stories, comments, and reviews.
    <br/>
    Uizard's component template let you incorporate interactive and engaging social media components into your mobile or web app. These customizable, embeddable templates can be used within seconds — no design experience or coding required.
    <br/>
    h3:Social component template that blend beautifully with the rest of your site's content 
    <br/>
    Social component template from Uizard will always reflect your chosen theme and as such, will blend beautifully with the rest of your site's content and features, giving them the look and feel you'd like to impart.
    <br/>
    Uizard's social component template are clean and sleek, so that your content is right where you want it to be — and they can be easily integrated with any design.
    "
    pages={[
      "Comments or reviews",
      "Social media postcard",
      "Footer work message app",
      "App header and stories",
      "Conversation list and stories",
      "Chat",
    ]}
    img1={data.image1.childImageSharp}
    img1alt="Screenshot showcasing the component template for designing social apps"
    img2={data.image2.childImageSharp}
    img2alt="Screenshot showcasing the component template for designing social apps"
    img3={data.image3.childImageSharp}
    img3alt="Screenshot showcasing the component template for designing social apps"
    img4={data.image4.childImageSharp}
    img4alt="Screenshot showcasing the component template for designing social apps"
    img5={data.image5.childImageSharp}
    img5alt="Screenshot showcasing the component template for designing social apps"
  />
);

export default ComponentTemplate;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: { eq: "templates/component-templates/social/social_1.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: { eq: "templates/component-templates/social/social_2.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: { eq: "templates/component-templates/social/social_3.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: { eq: "templates/component-templates/social/social_4.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: { eq: "templates/component-templates/social/social_5.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
